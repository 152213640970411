import React from "react";
import sjoen from "../IMG_3545.jpeg";

function InformationPage() {
  return (
    <div>
      <h1 className="App-header-frontpage">Informasjon</h1>
    </div>
  );
}

export default InformationPage;
