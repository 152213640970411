import React from "react";

function ØnskelistePage() {
  return (
    <div>
      <h1 className="App-header-frontpage">
        Her kommer ønskelisten til brudeparet
      </h1>
    </div>
  );
}

export default ØnskelistePage;
