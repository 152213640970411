import React from "react";

function HusetPage() {
  return (
    <div className="App-parent-container">
      <h1 className="App-header">Her kommer informasjon om stedet</h1>
    </div>
  );
}

export default HusetPage;
